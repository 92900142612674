.heroWrapper {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("/src/assets/forest-bridge.jpeg"); */
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../assets/forest-bridge.jpeg");

  background-size: cover;
  height: 100vh;
  width: 100vw;
  opacity: 80%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroText {
  text-align: center;
  position: relative;
  font-size: 2rem;
  color: #d9d9d9;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 1);
}
